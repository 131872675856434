export * from './styles';

export const COMMON = {
    ROOT: 'root',
    LINK_TARGET: '_blank',
    LOGO: '/logo.png',
    EMPTY_SPACE: ' ',
    EMPTY_STRING: "",
};

export const SOCIAL_MEDIA_LINKS = {
    TWITTER: 'https://twitter.com/',
    FACEBOOK: 'https://www.facebook.com/',
    LINKEDIN: 'https://www.linkedin.com/',
    GOOGLE: 'https://www.google.com/',
};
